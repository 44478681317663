import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import BannerIntro from "../components/BannerIntro"
import Divider from "../components/Divider"
import Seo from "../components/Seo"
import { GatsbyImage } from "gatsby-plugin-image"

const CertificationsPage = (props) => {
  const pageData = props.data.wpPage
  const seoData = props.data.wpPage.seo

  return (
    <Layout extraClass="certifications" pageId={pageData.databaseId}>
      <Seo
        title={seoData.title}
        description={seoData.metaDesc}
        canonical={seoData.canonical}
        keywords={seoData.metaKeywords}
      />
      <BannerIntro
        imageUrl={
          pageData.certifications.bannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        altText={pageData.certifications.bannerImage.altText}
        headline={pageData.title}
        copy={pageData.certifications.certCopy}
      />
      <Divider />
      <div className="container">
        <div
          className="cert-list grid-6"
          dangerouslySetInnerHTML={{
            __html: pageData.certifications.certList,
          }}
        />
        <div className="img-text">
          <div className="fancy-text italic-red">
            {pageData.certifications.imageText}
          </div>
          <GatsbyImage
            image={
              pageData.certifications.certRightImage.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={pageData.certifications.certRightImage.altText}
          />
        </div>
      </div>
    </Layout>
  )
}

export default CertificationsPage

export const certsQuery = graphql`
  query certpageQuery {
    wpPage(databaseId: { eq: 11 }) {
      databaseId
      seo {
        title
        canonical
        metaDesc
        metaKeywords
      }
      title
      certifications {
        certCopy
        imageText
        certRightImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        certList
        bannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
